import React from 'react';
import { useAuth } from '../lib/auth';
import { useUsers } from '../lib/db';

import { DataGrid } from '@mui/x-data-grid';
import { Typography } from '@mui/material';

import NotFoundPage from './NotFound';

/**
 * TO ADD AN ADMIN: add their email to the auth.js page, and their uid to the firestore.rules
 */

const Admin = () => {
  const auth = useAuth();
  const users = useUsers();

  const columns = [
    { field: 'createdAt', headerName: 'Created At', width: 120,
      renderCell: ({ value }) => (
        value
          ?.toDate()
          ?.toLocaleString('en-US', { month: 'long', day: 'numeric' }) // .substr(0, 24)
      )
    },
    { field: 'email', headerName: 'Email', width: 280,
      renderCell: ({ value }) => (
        <a href={"mailto:" + value} target='_blank' rel="noreferrer" style={{color: '#343366'}}>
          {value}
        </a>
      )
    },
    { field: 'fullname', headerName: 'Full Name', width: 180, /* editable: true */ }, //, valueGetter: ({ value }) => value?.toUpperCase() },
    { field: 'employer', headerName: 'University/Employer', width: 200, /* editable: true, */ sortable: false },
    { field: 'title', headerName: 'Student/Occupation (Title)', width: 240, /* editable: true, */ sortable: false },
    { field: 'location', headerName: 'Location', width: 120, /* editable: true, */ sortable: false }
  ];

  if (!auth.user) {
    return <Typography variant="h4">Loading...</Typography>
  }

  if (!auth.isAdmin) {
    return <NotFoundPage />;
  }

  return (
    <div style={{ width: '100%' }}>
      <DataGrid
        sx={{ backgroundColor: '#fff' }}
        rows={users}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10, 15]}
      />
      {/* <Typography sx={{color: '#a0a0b996'}}>** double-click to edit</Typography> */}
    </div>
  );
}

export default Admin;
