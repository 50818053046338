import React from "react";

const questions = [
  ['In the book, what is the third word under the main heading "MIXED SYNTHESIS"?', 'class'],
  ['In the book, what is the third word under the heading "Defects"?', 'by'],
  ['In the book, what is the third word under the heading "Point Path Generation: Three or More Positions"?', 'target']
];

/**
 * This custom React hook, useQuestions, returns a random question and its corresponding answer from a predefined array.
 * 
 * Possible Questions:
 *   ['In the book, what is the third word on page 11?', 'point']
 *   ['In the book, what is the second word on page 13?', 'method']
 *   ['In the book, what is the third word on page 15?', 'be']
 * 
 * @returns {[string, string] | [string, null]} An array containing a question and answer.
 */
export function useQuestions() {
  const [randomNumber] = React.useState(Math.floor(Math.random() * questions.length));
  const question = questions[randomNumber];

  if (!question) {
    return ['Error Loading Verification Question', null];
  }

  return question;
};
