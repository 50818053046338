import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Tabs, Tab, Typography, TextField as MuiTextField, Button } from '@mui/material';
import { login, register, resetPassword } from '../lib/auth';
import { useQuestions } from '../lib/useQuestions';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#fff',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
    maxWidth: '600px',
    margin: '0px auto',
    padding: theme.spacing(2)
  },
  tabs: {
    marginBottom: theme.spacing(2),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2.5),
    maxWidth: '400px',
    margin: '0px auto',
  },
  button: {
    alignSelf: 'center',
    width: 'fit-content',
    margin: 16
  },
}));

const TextField = (props) => (
  <MuiTextField size='small' {...props} />
);

const LoginRegistration = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const [question, answer] = useQuestions();

  const handleTabChange = (event, newValue) => {
    event.preventDefault();

    setActiveTab(newValue);
  };

  const handleLoginFormSubmit = async (event) => {
    event.preventDefault();

    const email = event.target.email.value?.trim();
    const password = event.target.password.value?.trim();
    const resp = await login(email, password);
    
    console.log("handleLoginFormSubmit", {resp});
    if (resp.success) {
      navigate("/videos");
    }
  };

  const handleRegisterFormSubmit = async (event) => {
    event.preventDefault();

    const email = event.target.email.value;
    const password = event.target.password.value;

    const fullname = event.target.fullname.value?.trim();
    const employer = event.target.employer.value?.trim();
    const title = event.target.title.value?.trim();
    const location = event.target.location.value?.trim();
    const userAnswer = event.target.answer?.value?.trim().toLowerCase();

    if (userAnswer !== answer) {
      alert("Security Question was incorrect");
      return;
    }

    const user = { email, fullname, employer, title, location, createdAt: new Date() };
    const resp = await register(email, password, user);
    console.log("handleRegisterFormSubmit", {resp});
    if (resp.success) {
      navigate("/videos");
    }
  };

  const handleResetPassword = async (event) => {
    event.preventDefault();

    console.log("handleResetPassword", {event});
    const email = event.target.email.value?.trim();
    const resp = await resetPassword(email);
    console.log("handleResetPassword", {resp});
    if (resp.success) {
      window.location.reload();
    }
  }

  const PasswordResetForm = () => {
    return (
      <form className={classes.form} onSubmit={handleResetPassword}>
        <TextField label="Email" type="email" name="email" required />
        <Button className={classes.button} type="submit" variant="contained">
          Send Email
        </Button>
        <Typography variant='caption'>** Please check your spam folder</Typography>
      </form>
    );
  };

  const LoginForm = () => {
    return (
      <form className={classes.form} onSubmit={handleLoginFormSubmit}>
        <TextField label="Email" type="email" name="email" required />
        <TextField label="Password" type="password" name="password" required />
        <Button className={classes.button} type="submit" variant="contained">
          Login
        </Button>
        <Link className='blue-active' style={{ margin: '0px auto' }} onClick={(e) => handleTabChange(e, 2)}>
          Forgot password?
        </Link>
      </form>
    );
  };

  const RegisterForm = () => {

    const Question = () => (<>
      <Typography>{question}</Typography>
      <TextField label="Security Question" type="text" name="answer" required disabled={!answer} />
    </>)

    return (
      <form className={classes.form} onSubmit={handleRegisterFormSubmit}>
        <TextField label="Email" type="email" name="email" required />
        <TextField label="Password" type="password" name="password" required />
        <hr />
        <TextField label="Full Name" type="text" name="fullname" required />
        <TextField label="University/Employer" type="text" name="employer" required />
        <TextField label="Student/Occupation (Title)" type="text" name="title" required />
        <TextField label="Location" type="text" name="location" required />
        <hr />
        <Question />
        <Button type="submit" variant="contained" className={classes.button}>
          Register
        </Button>
      </form>
    );
  };

  return (
    <div className={classes.container}>
      <Tabs value={activeTab} onChange={handleTabChange} textColor="primary" indicatorColor="primary" className={classes.tabs} centered>
        <Tab label="Login" />
        <Tab label="Register" />
        <Tab label="Reset Password" sx={{ display: 'none' }} />
      </Tabs>
      <div id="login" hidden={activeTab !== 0}>
        <LoginForm />
      </div>
      <div id="register" hidden={activeTab !== 1}>
        <RegisterForm />
      </div>
      <div id="password" hidden={activeTab !== 2}>
        <PasswordResetForm />
      </div>
    </div>
  );
};

export default LoginRegistration;
