import React from 'react';

import "./Home.css";

import { Link } from 'react-router-dom';

import BookCover from "../assets/book-cover.png";

const Home = () => {

  return (
    <div className='home'>
      <div className="grid">
        <div className="grid-item training-videos">
          <div className="illustration">
            <img src={BookCover} alt="Video Screenshot" style={{maxWidth: '240px'}}/>
          </div>
          <div className="content">
            <h3>Training Videos</h3>
            <div>
              <p>These training videos are a supplement to the textbook and provide a visual step by step guide to the application of Pole and Rotation angle Constraints.</p>
              <Link to="/videos" className="btn">Watch</Link>
            </div>
          </div>
        </div>
        <div className="grid-item border-right">
          <h4>The Book</h4>
          <div>
            <p><span>Planar Linkage Synthesis: A modern CAD based approach</span></p>
            <Link to="/book" className="btn">Learn More</Link>
          </div>
        </div>
        <div className="grid-item">
          <h4>About us</h4>
          <div>
            <p><span>PRCLinks.com is dedicated to teaching the principles of planar linkage synthesis using pole and rotation angle constraints (PRC).</span></p>
            <Link to="/about" className="btn">Read More</Link>
          </div>
        </div>
      </div>
    </div>
  );
};


export default Home;
