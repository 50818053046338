import { useState, useEffect } from "react";
import { db } from "./firebase";
import { getDocs, collection, orderBy, query } from "firebase/firestore";

async function getUsers() {
  const querySnapshot = await getDocs(query(collection(db, "users"), orderBy("createdAt", "desc")));
  const users = querySnapshot.docs.map((doc) => ({...doc.data(), id:doc.id }));
  return users;
}

function useUsers() {
  const [users, setUsers] = useState([]);


  useEffect(() => {

    const fetchData = async () => {
        
      getUsers()
        .then(setUsers)
        .catch(err => {
          console.log("You are Unauthorized to View this page, redirecting to Home Page...")
        })

    }

    fetchData();
    
  }, []);

  return users;

}

export { getUsers, useUsers };
