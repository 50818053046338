import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Container, Box } from '@mui/material';

const NotFoundPage = () => {
  return (
    <Container maxWidth="sm">
      <Box sx={{ marginTop: '2rem', textAlign: 'center' }}>
        <Typography variant="h1">404</Typography>
        <Typography variant="h5" gutterBottom>
          Page Not Found
        </Typography>
        <Typography variant="body1">
          The page you are looking for does not exist.
        </Typography>
        <Link to="/" sx={{ marginTop: '2rem' }} className="btn">
          Go to Home
        </Link>
      </Box>
    </Container>
  );
};

export default NotFoundPage;
