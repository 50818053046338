import React from 'react';
import './Book.css';

import BookCover from "../assets/book-cover.png"

const Book = () => {
  
  const barnesandnoble = "https://www.barnesandnoble.com/w/planar-linkage-synthesis-ronald-allen-zimmerman/1143258843?ean=9798218173586";

  return (
    <div id="book">

      <section className='info book-info'>

        <img src={BookCover} alt="Book Cover" style={{ maxWidth: '240px' }} />

        <div>
          <h2>Planar Linkage Synthesis</h2>

          <p>
            Planar Linkage Synthesis presents a modern CAD based approach to the design of linkage mechanisms. Linkage design is a long-standing mechanical engineering problem. New developments in CAD modeling programs allow the creation of constraint based moveable geometry. This new tool opens the door to linkage design methods not previously possible. The design method presented is called Pole and Rotation angle Constraints (PRC). PRC is faster and easier than traditional planar linkage synthesis methods. It is the first design methodology capable of solving every type of planar linkage synthesis problem in any combination. It utilizes the most ubiquitous design tool (CAD). It is visual, intuitive, and simple to learn and use. It is an ideal method for the practicing engineer and student to learn and use in the design of linkage mechanisms.
          </p>

          <a href={barnesandnoble} target="_blank" rel="noreferrer">Purchase Here</a>
        </div>

      </section>
      
      <section>
        <h2>What People Say about PRC:</h2>
        <blockquote>
          "Ron's CAD based pole and rotation angle methods will amaze you with how quickly you're able to generate new mechanism designs."<br />
          <cite>Pierre Larochelle, Department Head SDSMT</cite>
        </blockquote>
        <blockquote>
          "It is the best method I used to design linkage mechanisms."<br />
          <cite>Sunil Patil, Engineering Manager</cite>
        </blockquote>
        <blockquote>
          "Thanks for your training sessions on linkage synthesis. It is a very fast and easy method to solve planar linkage synthesis problems."<br />
          <cite>Tushar Gaikwad, Engineering Manager</cite>
        </blockquote>
        <blockquote>
          "This book will definitely help engineering aspirants and professionals."<br />
          <cite>Shriram Agalave, Innovation Engineering</cite>
        </blockquote>
      </section>
    </div>
  );
};

export default Book;
