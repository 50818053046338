/**
 * https://blog.logrocket.com/user-authentication-firebase-react-apps/
 */

import { initializeApp } from "firebase/app";

import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";

import {
  getFirestore,
  setDoc,
  doc,
  getDocs,
  collection,
  orderBy,
  query
} from "firebase/firestore";

import { auth, db } from "./firebase";

import { useAuthState } from "react-firebase-hooks/auth";

const useAuth = () => {
  const [user, loading, error] = useAuthState(auth);

  const _auth = {
    get isLoggedIn() {
      return user !== null;
    },
    get isAdmin() {
      return ["oS94r6arGfbkUGinpPKQeLyyjg23", "7OZ6ByD4ovWrVNXbDyIlvulLZRv1"].includes(user?.uid)
    },
    user,
    loading,
    error
  };

  return _auth;
}

const login = async (email, password) => {
  try {
    const resp = await signInWithEmailAndPassword(auth, email, password);
    console.log("login", {resp});
    return { success: true, message: "Successfully Logged in!" };
  } catch (err) {
    console.error(err);
    console.log(err);
    console.log({err, m: err.message, c: err.code});
    alert(err.message?.replace("Firebase: ", ""));
    return { success: false, message: err.message };
  }
}

const register = async (email, password, user) => {
  try {
    const resp = await createUserWithEmailAndPassword(auth, email, password);
    await setDoc(doc(db, "users", resp.user.uid), user);
    console.log("register", {resp});
    return { success: true, message: "Account Successfully Created!" };
  } catch (err) {
    console.error(err);
    console.log({err, m: err.message, c: err.code});
    alert(err.message?.replace("Firebase: ", ""));
    return { success: false, message: err.message };
  }
}

const logout = async () => {
  const resp = await signOut(auth);
  console.log("logout", {resp});
  return { success: true, message: "Successfully Logged out" };
}

const resetPassword = async (email) => {
  try {
    const resp = await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
    console.log("resetPassword", {resp});
    return { success: true, message: "Password reset link sent!" };
  } catch (err) {
    console.error(err);
    console.log(err);
    alert(err.message?.replace("Firebase: ", ""));
    return { success: false, message: err.message };
  }
}

export {
  useAuth,
  login,
  register,
  logout,
  resetPassword
};
