import React, { useMemo } from 'react';
import { useAuth } from '../lib/auth';

import { Link } from 'react-router-dom';
import { Button, Paper, Stack } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import LockIcon from '@mui/icons-material/Lock';

// Video Extensions: "m3u8" or "mp4"
import RBG1 from '../assets/Videos/RBG1- Pole and rotation angle.m3u8';
import RBG2 from '../assets/Videos/RBG2- 2 position synthesis.m3u8';
import RBG3 from '../assets/Videos/RBG3- 2 position verification.m3u8';
import RBG4 from '../assets/Videos/RBG4- 3 Position synthesis.m3u8';
import RBG5 from '../assets/Videos/RBG5- 3 Position verification.m3u8';
import RBG6 from '../assets/Videos/RBG6- 3 Positions no order defects.m3u8';
import RBG7 from '../assets/Videos/RBG7- 4&5 Position synthesis.m3u8';
import PP1  from '../assets/Videos/PP1- 2 Points synthesis.m3u8';
import PP2  from '../assets/Videos/PP2- 3 Points synthesis.m3u8';
import PP3  from '../assets/Videos/PP3- 3 Points verification.m3u8';
import PP4  from '../assets/Videos/PP4- 5 Points synthesis.m3u8';
import FG1  from '../assets/Videos/FG1- 2 Position synthesis.m3u8';
import FG2  from '../assets/Videos/FG2- 3 Position synthesis.m3u8';
import FG3  from '../assets/Videos/FG3- 3 Position verification.m3u8';
import FG4  from '../assets/Videos/FG4 - 5 Position synthesis.m3u8';
import Mix1 from '../assets/Videos/Mix1 2-1-1 Procedure.m3u8';
import Mix2 from '../assets/Videos/Mix2 2-2-2 poles.m3u8';
import Mix3 from '../assets/Videos/Mix3 2-2-2 constraints.m3u8';
import Mix4 from '../assets/Videos/Mix4 2-2-2 verification.m3u8';

import VideoJS from '../components/VideoJS';

function Video({ index, label, src }) {
  
  const playerRef = React.useRef(null);

  const videoJsOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    playsinline: true,
    sources: [{
      src: src,
      type: src.endsWith('.m3u8') ? 'application/x-mpegURL' : 'video/mp4',
    }]
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on('waiting', () => {
      console.log('player is waiting');
    });

    player.on('dispose', () => {
      console.log('player will dispose');
    });
  };

  return <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
}

function AccordionItem(props) {
  const { label, children, disabled } = props;

  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      elevation={0}
      disabled={disabled}
      disableGutters
      square
      sx={{
        '&:before': { display: 'none',  /* Remove lines */ },
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
      }}
    >
      <AccordionSummary 
        expandIcon={disabled ? <LockIcon /> : <ExpandMoreIcon />}
        sx={{ '&:hover': { backgroundColor: 'rgba(0, 0, 0, .04)' }}}
      >
        <SmartDisplayIcon sx={{ pl: 4, pr: 2, color: 'rgba(0, 0, 0, 0.54)' }} />
        <Typography>{label}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{p: 0}}>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

function AccordionSection(props) {
  const { label, children } = props;

  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      elevation={0}
      disableGutters
      square
      sx={{
        '&:before': { display: 'none',  /* Remove lines */ },
        borderRadius: "4px"
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
          borderBottom: '1px solid rgba(224, 224, 224, 1)'
        }}
      >
        <VideoLibraryIcon sx={{ pr: 2, color: 'rgba(0, 0, 0, 0.54)' }} />
        <Typography>{label}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{p: 0}}>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

function DisplayVideos({ files, data }) {
  return (
    <Paper
      elevation={0}
      sx={{
        border: "1px solid rgba(224, 224, 224, 1)",
        borderRadius: "4px"
      }}
    >
      {data.map(({title, videos}, index) => (
        <AccordionSection key={index} label={title}>
          {videos.map(video => (
            <AccordionItem key={video} label={files[video].label} disabled={files[video].disabled}>
              {!files[video].disabled && <Video index={video} label={files[video].label} src={files[video].src} />}
            </AccordionItem>
          ))}
        </AccordionSection>
      ))}
    </Paper>
  )
}

function LockedBox() {
  return (
    <Stack
      direction="column"
      alignItems="center"
      rowGap="16px"
      p="32px"

      color="white"
      backgroundColor="#343366"
      borderRadius="4px"
    >
      <LockIcon />
      <Typography fontWeight="bold" color="white">
        15 Additional Videos with Book Purchase and Registration
      </Typography>
      <Link to="/login">
        <Button variant="outlined" color="secondary">Login/Register</Button>
      </Link>
    </Stack>
  )
}

const Videos = () => {
  // Video.js Flag for Google Analytics
  window.HELP_IMPROVE_VIDEOJS = false;

  const auth = useAuth();

  const files = useMemo(() => ({
    'RBG1': { label: 'RBG1- Pole and rotation angle', src: RBG1 },
    'RBG2': { label: 'RBG2- 2 Position synthesis', src: RBG2 },
    'RBG3': { label: 'RBG3- 2 Position verification', src: RBG3 },
    'RBG4': { label: 'RBG4- 3 Position synthesis', src: RBG4 },
    'RBG5': { label: 'RBG5- 3 Position verification', src: RBG5 },
    'RBG6': { label: 'RBG6- 3 Positions no order defects', src: RBG6 },
    'RBG7': { label: 'RBG7- 4&5 Position synthesis', src: RBG7 },
    'PP1' : { label: 'PP1- 2 Points synthesis', src: PP1 },
    'PP2' : { label: 'PP2- 3 Points synthesis', src: PP2 },
    'PP3' : { label: 'PP3- 3 Points verification', src: PP3 },
    'PP4' : { label: 'PP4- 5 Points synthesis', src: PP4 },
    'FG1' : { label: 'FG1- 2 Position synthesis', src: FG1 },
    'FG2' : { label: 'FG2- 3 Position synthesis', src: FG2 },
    'FG3' : { label: 'FG3- 3 Position verification', src: FG3 },
    'FG4' : { label: 'FG4- 5 Position synthesis', src: FG4 },
    'Mix1': { label: 'Mix1- 2-1-1 Procedure', src: Mix1 },
    'Mix2': { label: 'Mix2- 2-2-2 Poles', src: Mix2 },
    'Mix3': { label: 'Mix3- 2-2-2 Constraints', src: Mix3 },
    'Mix4': { label: 'Mix4- 2-2-2 Verification', src: Mix4 },
  }), []);

  const intro = useMemo(() => ([
    { title: 'Rigid body Guidance', note: 'RBG1-2', videos: ['RBG1', 'RBG2'] },
    { title: 'Point Path', note: 'PP1', videos: ['PP1'] },
    { title: 'Function generator', note: 'FG1', videos: ['FG1'] },
  ]), []);

  const all = useMemo(() => ([
    { title: 'Rigid body Guidance', note: 'RBG3-7', videos: ['RBG1', 'RBG2', 'RBG3', 'RBG4', 'RBG5', 'RBG6', 'RBG7'] },
    { title: 'Point Path', note: 'PP2-4', videos: ['PP1', 'PP2', 'PP3', 'PP4'] },
    { title: 'Function generator', note: 'FG2-4', videos: ['FG1', 'FG2', 'FG3', 'FG4'] },
    { title: 'Mixed Synthesis', note: 'M1-4', videos: ['Mix1', 'Mix2', 'Mix3', 'Mix4'] },
  ]), []);

  const data = useMemo(() => auth.isLoggedIn ? all : intro, [auth, all, intro]);

  return (
    <Stack rowGap={4}>
      {!auth.isLoggedIn && <h2 style={{textAlign: 'center'}}>Introduction to PRC</h2>}
      <DisplayVideos files={files} data={data} />
      {!auth.isLoggedIn && <h2 style={{textAlign: 'center'}}>Advanced PRC application</h2>}
      {!auth.isLoggedIn && <LockedBox />}
    </Stack>
  );
};

export default Videos;
