import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import reportWebVitals from './reportWebVitals';
import { Home, Videos, Login, Book, AboutUS, Admin, NotFound } from './pages';
import { Header, Footer } from './components';
import './index.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#343366'
    },
    secondary: {
      main: '#a0a0b9'
    }
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
    <div className="app">
      <Router>
        <React.StrictMode>
          <Header />
          <main className='main'>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/videos" element={<Videos />} />
              <Route path="/login" element={<Login />} />
              <Route path="/book" element={<Book />} />
              {/* <Route path="/contact" element={<ContactUS />} /> */}
              <Route path="/about" element={<AboutUS />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </main>
          <Footer />
        </React.StrictMode>
      </Router>
    </div>
  </ThemeProvider>
);

reportWebVitals();
