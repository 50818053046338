import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBYnh7gs9_RXQNnu55bDOUY5uq42qaqM3s",
  authDomain: "planar-linkage-synthesis.firebaseapp.com",
  projectId: "planar-linkage-synthesis",
  storageBucket: "planar-linkage-synthesis.appspot.com",
  messagingSenderId: "726626099418",
  appId: "1:726626099418:web:82fbd05c61a9429801c5e3",
  measurementId: "G-WXX9RKT7SD"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
