// ./components/Footer.js

import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <p>© Ron Zimmerman 2023, <a href="mailto:prclinks@gmail.com" target='_blank' rel="noreferrer" className='blue-active'>prclinks@gmail.com</a></p>
    </footer>
  );
};

export default Footer;
