import React from 'react';
import './AboutUS.css';

const AboutUS = () => {
  return (
    <div id="about-us">
      <h1>About Us</h1>
      <p>
        PRCLinks.com is dedicated to teaching the principles of planar linkage synthesis using pole and rotation angle constraints (PRC). PRC is a modern CAD based approach to the synthesis of planar linkages and is the companion site to the book "Planar Linkage Synthesis" by Ron Zimmerman.
      </p>
      <p>
        Ron Zimmerman discovered PRC in 2012. Since then, he has published four technical peer-reviewed papers on the method through the American Society of Mechanical Engineers (ASME). He has given invited lectures and taught seminars on the topic to university professors and students. PRC is already being taught in several universities. This book takes the PRC principles and presents them in a way suited to the undergraduate mechanical engineering student. It is intended to replace the existing content on linkage synthesis typically taught in a course on machine design.
      </p>
      <p>
        Ron started working at Magna Seating in 1996 and is responsible for the design of numerous automotive seating mechanisms and structures. He has been using PRC since its discovery. He has taught PRC principles throughout Magna Seating, and it is now the only method used to design linkages there.
      </p>
      <p>
        Ron is a licensed professional engineer with over 25 years of practical experience in the automotive industry. He holds a Bachelor's degree in Mechanical Engineering from Brigham Young University and a Master's degree in Mechanical Engineering from Purdue University. His graduate work focused on kinematics and mechanism design.
        {/* He can be reached at <a href="mailto:prclinks@gmail.com" target='_blank' rel="noreferrer" className='blue-active'>prclinks@gmail.com</a>. */}
      </p>
      <p>
        If you have questions or are interested in additional training or consulting services,
        please email us at <a href="mailto:prclinks@gmail.com" target='_blank' rel="noreferrer" className='blue-active'>prclinks@gmail.com</a> with a description of your request.
      </p>
    </div>
  );
};

export default AboutUS;
