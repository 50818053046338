import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import MuiMenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useAuth, logout } from '../lib/auth';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign: 'center'
  },
  navLink: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '96px',
    minHeight: '48px',
    textDecoration: 'none',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },
    '&:active': {
      backgroundColor: theme.palette.primary.main
    }
  },
  activeNavLink: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '96px',
    minHeight: '48px',
    textDecoration: 'none',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    '&:active': {
      backgroundColor: theme.palette.primary.main
    }
  }
}));

const StyledMenuItem = styled(MuiMenuItem)((props) => ({
  minWidth: '200px',
  '&:hover': {
    backgroundColor: '#3433664a'
  },
  '&:active': {
    backgroundColor: '#343366',
    color: 'white'
  }
}));

function MenuItem({isActive, ...props}) {
  return <StyledMenuItem {...props} sx={{ backgroundColor: isActive ? '#3433664a' : '' }} />;
}

const Header = () => {
  const auth = useAuth();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const upmd = useMediaQuery(theme.breakpoints.up('md'));

  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const location = useLocation();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLinkClick = (path) => {
    navigate(path);
    handleClose();
  };

  const handleLogout = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Are you sure you want to logout?")) {
      logout();
      navigate("/login");
    }
  };

  return (
    <AppBar position="static" className={classes.header} elevation={0} sx={{ backgroundColor: { xs: null, md: 'transparent' } }}>
      <Toolbar sx={{ flexDirection: { xs: 'row', md: 'column' }, padding: '0!important' }}>
        
        <Box sx={{ flexGrow: 1, marginTop: 2, marginBottom: 2, cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} onClick={() => handleLinkClick('/')}>
          <Typography variant={upmd ? "h4" : "h6"} className={classes.title} sx={{ color: { xs: 'white', md: 'black' }, fontWeight: 'bold' }}>
            Planar Linkage Synthesis
          </Typography>
          <Typography variant="h6" className={classes.title} sx={{ display: { xs: 'none', md: 'initial' }, color: { xs: 'white', md: 'black' } }}>
            A modern CAD based approach
          </Typography>
        </Box>

        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ marginRight: 2 }}
            onClick={handleMenu}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleLinkClick('/')} isActive={location.pathname === '/'}>
              Home
            </MenuItem>
            <MenuItem onClick={() => handleLinkClick('/videos')} isActive={location.pathname === '/videos'}>
              Videos
            </MenuItem>
            <MenuItem onClick={() => handleLinkClick('/book')} isActive={location.pathname === '/book'}>
              Book
            </MenuItem>
            <MenuItem onClick={() => handleLinkClick('/about')} isActive={location.pathname === '/about'}>
              About us
            </MenuItem>
            {auth.isAdmin && <hr />}
            {auth.isAdmin && <MenuItem onClick={() => handleLinkClick('/admin')} isActive={location.pathname === '/admin'}>
              Admin
            </MenuItem>}
            {/* <MenuItem onClick={() => handleLinkClick('/contact')} isActive={location.pathname === '/contact'}>
              Contact us
            </MenuItem> */}
            <hr />
            {!auth.isLoggedIn ? 
              <MenuItem onClick={() => handleLinkClick('/login')} sx={{ backgroundColor: "#3433664a", fontWeight: 'bold' }} isActive={location.pathname === '/login'}>
                Login
              </MenuItem> :
              <MenuItem onClick={handleLogout} sx={{fontWeight: 'bold'}}>
                Logout
              </MenuItem>}
          </Menu>
        </Box>

        <Box sx={{ display: { xs: 'none', md: 'flex' }, backgroundColor: '#343366', width: '100%', justifyContent: 'center' }}>
          <Box sx={{ paddingLeft: '32px', paddingRight: '32px', maxWidth: '1200px', width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Link to="/" className={location.pathname === '/' ? classes.activeNavLink : classes.navLink}>
              Home
            </Link>
            <Link to="/videos" className={location.pathname === '/videos' ? classes.activeNavLink : classes.navLink}>
              Videos
            </Link>
            <Link to="/book" className={location.pathname === '/book' ? classes.activeNavLink : classes.navLink}>
              Book
            </Link>
            <Link to="/about" className={location.pathname === '/about' ? classes.activeNavLink : classes.navLink}>
              About us
            </Link>
            <div style={{flex: '1 0'}}></div>
            {auth.isAdmin && <Link to="/admin" className={location.pathname === '/admin' ? classes.activeNavLink : classes.navLink}>
              Admin
            </Link>}
            {/* <Link to="/contact" className={location.pathname === '/contact' ? classes.activeNavLink : classes.navLink}>
              Contact us
            </Link> */}
            {!auth.isLoggedIn ? (
              <Link to="/login" className={location.pathname === '/login' ? classes.activeNavLink : classes.navLink}>
                <Typography sx={{fontWeight: 'bold', color: 'white'}}>
                  Login
                </Typography>
              </Link>
            ) : (              
              <Button className={classes.navLink} sx={{borderRadius: 0}} onClick={handleLogout}>
                <Typography sx={{fontWeight: 'bold', color: 'white'}}>
                  Logout
                </Typography>
              </Button>
            )}
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
